import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Fade } from 'react-reveal';
import { Link } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import demoVideo from '../assets/demo-final.mp4';
import rightarrow from '../assets/right-arrow.svg';
import '../styles/Hero.css';

const Hero = ({ time = '20s' }) => {
  const { currentUser } = useAuth();
  const style = {
    '--time': time,
  };

  useEffect(() => {
    const videoElement = document.querySelector('.hero-video video');
    if (videoElement) {
      videoElement.load();
    }
  }, []);

  return (
    <div className="overarching-hero-container">
      <Helmet>
        <title>ragobble - Empowering Academic Research</title>
        <meta name="description" content="Leverage ragobble's AI-Driven platform to create searchable databases from your academic resources, including articles, books, and research papers. Enhance your research with intelligent search capabilities." />
        <meta name="keywords" content="academic research, AI, searchable databases, research papers, articles, books, ragobble, AI Search, RAG, retrieval augmented generation, academic search, educational resources" />
        <meta name="author" content="ragobble" />
        <meta property="og:title" content="ragobble - Empowering Academic Research" />
        <meta property="og:description" content="Leverage ragobble's AI-Driven platform to create searchable databases from your academic resources, including articles, books, and research papers. Enhance your research with intelligent search capabilities." />
        <meta property="og:image" content="/path/to/social-image.jpg" />
        <meta property="og:url" content="https://www.ragobble.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ragobble - Empowering Academic Research" />
        <meta name="twitter:description" content="Leverage ragobble's AI-Driven platform to create searchable databases from your academic resources, including articles, books, and research papers. Enhance your research with intelligent search capabilities." />
        <meta name="twitter:image" content="/path/to/social-image.jpg" />
      </Helmet>
      <header className="hero-container">
        <Fade cascade delay={100}>
          <div className="hero-text">
            <h1>Enhance Your Research<br />with Advanced AI Retrieval</h1>
            <br />
            <p>Leverage our platform to transform your academic resources — articles, books, research papers, videos, URLs — into AI-Searchable databases. Streamline your research workflow with powerful, AI-driven search capabilities.</p>
            <br />
            <Link to={currentUser ? '/workbench' : '/signup' }>
              <button className="button-view-tx">
                {currentUser ? 'Workbench' : 'Get Started'}
                <img src={rightarrow} className="hero-arrow" alt="Arrow pointing to the right" />
              </button>
            </Link>
          </div>
        </Fade>
        <Fade delay={250}>
          <div className="hero-video">
            <video src={demoVideo} autoPlay loop muted playsInline></video>
          </div>
        </Fade>
      </header>
      <main className="hero-scroller">
        <h2>Create a Knowledge-Base out of:</h2>
        <div className="scroll" style={style}>
          <div>
            <span>Research Papers</span>
            <span>Scholarly Articles</span>
            <span>Books</span>
            <span>Lecture Notes</span>
            <span>Conference Proceedings</span>
            <span>Podcasts</span>
            <span>YouTube Lectures</span>
            <span>PDFs</span>
            <span>CSVs</span>
            <span>DOCX</span>
          </div>
          <div>
            <span>Research Papers</span>
            <span>Scholarly Articles</span>
            <span>Books</span>
            <span>Lecture Notes</span>
            <span>Conference Proceedings</span>
            <span>Podcasts</span>
            <span>YouTube Lectures</span>
            <span>PDFs</span>
            <span>CSVs</span>
            <span>DOCX</span>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Hero;
